/* Otp.css */
.otp-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

    margin-top: calc(50vh - 250px);

    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .otp-container {
        margin-top: 100px;
    }
}

.otp-heading {
    text-align: center;
    margin-bottom: 20px;
}

.otp-label {
    font-weight: bold;
}

.otp-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #da6363;
    border-radius: 4px;
    margin-bottom: 30px;
}

.otp-button {
    width: 100%;
    padding: 10px;
    font-weight: bold;
}

.otp-message {
    margin-top: 20px;
}