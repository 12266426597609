@import url('https://fonts.googleapis.com/css?family=Poppins');

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: Poppins, sans-serif;
}

body {


}

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
}

.screen {
	background: linear-gradient(90deg, #5D54A4, #7C78B8);

	position: relative;
	height: 600px;
	width: 360px;
	box-shadow: 0px 0px 24px #5C5696;


}

.screen__content {
	z-index: 1;
	position: relative;
	height: 100%;
}

.screen__background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);
}

.screen__background__shape {
	transform: rotate(45deg);
	position: absolute;
}

.screen__background__shape1 {
	height: 520px;
	width: 520px;
	background: #FFF;
	top: -50px;
	right: 120px;
	border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
	height: 220px;
	width: 220px;
	background: #6C63AC;

	top: -172px;
	right: 0;
	border-radius: 32px;
}

.screen__background__shape3 {
	height: 540px;
	width: 190px;
	background: linear-gradient(270deg, #5D54A4, #6A679E);

	top: -24px;
	right: 0;
	border-radius: 32px;
}

.screen__background__shape4 {
	height: 400px;
	width: 200px;
	background: #7E7BB9;
	top: 420px;
	right: 50px;
	border-radius: 60px;
}

.login {
	width: 320px;
	padding: 30px;
	padding-top: 156px;
}

.login__field {
	padding: 20px 0px;
	position: relative;
}

.login__icon {
	position: absolute;
	top: 30px;
	color: #7875B5;
}

.login__input {
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 24px;
	font-weight: 700;
	width: 75%;
	transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: #6A679E;
}

.login__submit {
	background: #fff;
	font-size: 14px;
	margin-top: 30px;
	padding: 16px 20px;
	border-radius: 26px;
	border: 1px solid #D4D3E8;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
	color: #4C489D;
	box-shadow: 0px 2px 2px #5C5696;
	cursor: pointer;
	transition: .2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #6A679E;
	outline: none;
}

.button__icon {
	font-size: 24px;
	margin-left: auto;
	color: #7875B5;
}

.social-login {
	position: absolute;
	height: 140px;
	width: 160px;
	text-align: center;
	bottom: 0px;
	right: 0px;
	color: #fff;
}

.social-icons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.social-login__icon {
	padding: 20px 10px;
	color: #fff;
	text-decoration: none;
	text-shadow: 0px 0px 8px #7875B5;
}

.social-login__icon:hover {
	transform: scale(1.5);
}


.user-list-container {
	width: 100%;
	overflow-x: auto;
}

.table-container {
	max-width: 100%;
	overflow: auto;
}

@media screen and (max-width: 768px) {
	.table-container {
		width: 100%;
	}
}






/* .invoice-table////////////////////////// */


.invoice-table {
	border-collapse: collapse;
	width: 100%;
  }
  
  .invoice-table th, .invoice-table td {
	border-style: solid; 
	border-color: #ccc;
	border-width: 0 0 1px 0; 
	font-size: 0.85rem;
	vertical-align: middle;
  }
  
  .invoice-table th {
	background-color: #115db4;
	color: #fff;
	white-space: nowrap;
	font-weight: normal;
	padding: 7px 1rem;
}
  
.invoice-table td{
	padding-block: 1rem;
	padding-left: 1rem;
}


.custom-button {
	text-decoration: none;
	padding: 9px 1rem;
  }

.dark-button{
	background-color: #333;color: #fff;
}

.logout-button{
background-color: royalblue;color: #fff;
border: none;
  }

  .invoice-pdf-container{
	background-color: #000c;
	display: flex;
	justify-content: center;
	z-index: 20;
	position: fixed;
	top: 0; left: 0; right: 0; bottom: 0;
	color: #fff;
	padding: 1rem;
  }

  .invoice-pdf-container embed{
	width: 100%;
	max-width: 980px;
	height: 100%;
  }

  .invoice-pdf-container .close-btn{
	font-size: 1rem;
	margin: 1rem;
	border: 2px solid;
	padding: 2px .8rem;
	border-radius: 500px;
	cursor: pointer;
  }

  .invoice-pdf-container .close-btn:hover{
	background-color: #fff;
	color: #000;
  }